<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="150px" :model="form" class="demo-form-inline">
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.totalPlantingAreaUnit"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.totalPlantingArea"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.totalPlantingAreaDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="标题">
                <el-input v-model="form.totalAnnualOutputUnit"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.totalAnnualOutput"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.totalAnnualOutputDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="标题">
                <el-input v-model="form.mapEnterpriseCountUnit"></el-input>
              </el-form-item>
              <el-form-item label="数值">
                <el-input v-model="form.mapEnterpriseCount"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.mapEnterpriseCountDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

            <!-- <el-row>
                <div class="sub-title">产业示范基地实时画面</div>
            </el-row> -->
            <!-- <el-row>
                <el-form-item label="实时画面名称1">
                    <el-input v-model="form.jidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称2">
                    <el-input v-model="form.jidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称3">
                    <el-input v-model="form.jidiVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址3">
                    <el-input style="width: 550px" v-model="form.jidiVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称4">
                    <el-input v-model="form.jidiVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址4">
                    <el-input style="width: 550px" v-model="form.jidiVedio4Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称5">
                    <el-input v-model="form.jidiVedio5Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址5">
                    <el-input style="width: 550px" v-model="form.jidiVedio5Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称6">
                    <el-input v-model="form.jidiVedio6Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址6">
                    <el-input style="width: 550px" v-model="form.jidiVedio6Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称7">
                    <el-input v-model="form.jidiVedio7Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址7">
                    <el-input style="width: 550px" v-model="form.jidiVedio7Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称8">
                    <el-input v-model="form.jidiVedio8Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址8">
                    <el-input style="width: 550px" v-model="form.jidiVedio8Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称9">
                    <el-input v-model="form.jidiVedio9Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址9">
                    <el-input style="width: 550px" v-model="form.jidiVedio9Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: ''
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen3LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen3DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            //const isJPG = file.type === 'image/jpeg';
            //const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>