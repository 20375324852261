<template>
    <div>
    <!-- 基地人员 -->
    
    <div class="sub-title">企业信息</div>
        <el-button type="primary" style="min-width: 88px;" v-if="tableData.length < 4" @click="add">新增</el-button>
        <el-table border :data="tableData" class="mt20">
            <el-table-column prop="enterpriseName" label="企业名称"> </el-table-column>
            <el-table-column prop="enterpriseId" label="企业id"> </el-table-column>
            <el-table-column prop="enterpriseValue" label="值"> </el-table-column>
            <el-table-column prop="keyEnterprises" label="是否重点">
                <template slot-scope="scope">
                    <span v-if="scope.row.keyEnterprises == 'Y'">是</span>
                    <span v-if="scope.row.keyEnterprises == 'N'">否</span>
                </template>
                
            </el-table-column>
            <el-table-column prop="mapX" label="X轴(px)"> </el-table-column>
            <el-table-column prop="mapY" label="Y轴(px)"> </el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.status == '1'">启用</span>
                    <span v-if="scope.row.status == '0'">停用</span>
                </template>
                
            </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
                    <!-- <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <div style="overflow: hidden;">
            <div style="margin-top: 12px; float: right">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </div>
        </div>
        <!-- <el-row class="ta_c">
            <el-button type="primary" class="mt20" style="min-width: 88px;" @click="onSaveFootInfo">保存</el-button>
        </el-row> footerPageInfo -->
        <el-dialog :title="title" class="pop"  width="500px" :visible.sync="dialog">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
                <el-form-item label="企业名称" prop="enterpriseName">
                    <el-input v-model="form.enterpriseName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="企业id"  prop="enterpriseId">
                    <el-input type="number" v-model="form.enterpriseId" disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="值" prop="enterpriseValue">
                    <el-input v-model="form.enterpriseValue" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否重点" prop="keyEnterprises">
                    <el-select v-model="form.keyEnterprises" placeholder="请选择">
                        <el-option
                        style="width: 100%;"
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="form.status" placeholder="请选择">
                        <el-option
                        style="width: 100%;"
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="X轴(px)" prop="mapX">
                    <el-input type="number" v-model="form.mapX" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Y轴(px)" prop="mapY">
                    <el-input type="number" v-model="form.mapY" autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data(){
        return{
            title: '新增',
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            dialog: false,
            form: {
                enterpriseName: '',
                enterpriseValue: '',
                status: '',
                mapX: '',
                mapY: '',
                enterpriseId: '',
                keyEnterprises: '',
            },
            rules: {
                enterpriseName: [{ required: true, message: '请填写企业名称', trigger: 'blur' }],
                enterpriseValue: [{ required: true, message: '请填写值', trigger: 'blur' }],
                mapX: [{ required: true, message: '请填写X轴', trigger: 'blur' }],
                mapY: [{ required: true, message: '请填写Y轴', trigger: 'blur' }],
                enterpriseId: [{ required: true, message: '请填写企业Id', trigger: 'change' }],
                keyEnterprises: [{ required: true, message: '请选择是否重点', trigger: 'change' }],
                status: [{ required: true, message: '请选择状态', trigger: 'change' }]
            },
            options: [{
                value: 'Y',
                label: '是'
                }, {
                value: 'N',
                label: '否'
            }],
            options1: [{
                value: 0,
                label: '停用'
                }, {
                value: 1,
                label: '启用'
            }],
        }
    },

    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            //查询地图企业信息
            this.qa.datascreen3EnterpriseMapInfoQueryForPageList(this.pageInfo).then(res => {
                if(res.respCode == '0000') {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                }
            })
        },
        handleSizeChange(page){
            this.pageInfo.pageSize = page;
            this.getInfo()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.getInfo()
        },
        edit(row) {
            this.title = '修改'
            this.form = JSON.parse(JSON.stringify(row))
            this.dialog = true
        },
        add() {
            this.title = '新增'
            this.form = {
                enterpriseName: '',
                enterpriseValue: '',
                status: '',
                mapX: '',
                mapY: '',
                enterpriseId: '',
                keyEnterprises: '',
            }
            this.dialog = true
        },
        addSubmit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.title == '新增') {
                        this.qa.datascreen3EnterpriseMapInfoDoBatchInsert(this.form).then(res => {
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.dialog = false;
                            this.getInfo()
                        })
                    }else {
                        //修改
                        this.qa.datascreen3EnterpriseMapInfoDoUpdate(this.form).then(res => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.dialog = false;
                            this.getInfo()
                        })
                    }
                } else {
                   
                    return false;
                }
            });
            
        },
        remove(id) {
            this.qa.datascreen3EnterpriseMapInfoDoDeleteById({id: id}).then(res => {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getInfo()
            })
        }
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    
    .pop >>> .el-time-spinner {
        box-sizing: border-box;
        padding-left: 20px;
    }
</style>